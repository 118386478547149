@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .about-us-image {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.33) -11.9%,
        #000000 86.5%
      ),
      url('./assets/noba_about.png');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
    position: relative;
  }
  .about-us-image-large {
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.33) -11.9%,
        #000000 86.5%
      ),
      url('./assets/noba_about.png');
    background-size: 50%;
    background-position: right;
    background-repeat: no-repeat;
    position: relative;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;1,400;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@700&family=Playfair+Display:wght@500&display=swap');

* {
  font-family: 'Montserrat', sans-serif;
}

body {
  background-color: #000000;
}

.hero-image {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      #000000 110.99%
    ),
    url('./assets/noba_hero.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.gradient-black {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.33) -11.9%,
    #000000 86.5%
  );
}

@font-face {
  font-family: filena;
  src: url('./assets/Filena-Bold.woff');
}

.filena {
  font-family: filena;
}

.merriweather {
  font-family: 'Merriweather';
}

.playfairDisplay {
  font-family: 'Playfair Display';
}

.galleryComponentGradient {
  background: linear-gradient(
    90.11deg,
    rgba(255, 255, 255, 0.41) -7.4%,
    rgba(255, 255, 255, 0) 77.05%
  );
}

.galleryComponentImageGradient {
  max-width: 100%;
  width: 100%;
  margin: auto;
}

.galleryComponentImageGradient::after {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 20.75%, #000000 88.49%);
  display: block;
  position: relative;
  margin-top: -150px;
  height: 150px;
  width: 100%;
  content: '';
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
